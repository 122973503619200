







































































import AppComponent from '@/mixins/ComponentMixin.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ConfirmTestResultApproval from '@/components/modals/ConfirmTestResultApproval.vue';
import TestResultComments from '@/components/TestResultComments.vue';
import { IRequisition, ITestResult } from '@/interfaces/requisitions';
import {
  dispatchGetCategories,
  dispatchGetNotes,
  dispatchCreateNote,
  dispatchUpdateNote,
} from '@/store/crud/actions';
import { readCategories, readPhysicianNotes } from '@/store/crud/getters';
// ts-ignore - ToDO: fix on TSLint -> ESLint move
import { intersectionWith, unionBy } from 'lodash';
import TestResultsTable from '@/components/tables/TestResultsTable.vue';
import { ICategory } from '@/interfaces/categories';
import { readUserProfile } from '@/store/main/getters';
import { INote, INoteInput } from '@/interfaces/notes';
import { dispatchAddNotification } from '@/store/main/actions';

@Component({
  components: { TestResultsTable, TestResultComments, ConfirmTestResultApproval },
})
export default class PhysicianReview extends AppComponent {
  @Prop({ type: Object }) public requisitionOnView: IRequisition;
  @Prop({ type: Array }) public testResults: ITestResult[];

  get notes() {
    const notes = readPhysicianNotes(this.$store);
    return notes?.sort((a, b) => {
      if ((a.updatedAt || a.createdAt) > (b.updatedAt || b.createdAt)) {
        return -1;
      }
      return 1;
    }) || [];
  }

  // Categories sorted by biomarkers from the requisition
  get groupedNotes() {
    let patientSex;
    if (this.requisitionOnView?.patient && typeof this.requisitionOnView.patient !== 'string') {
      patientSex = (this.requisitionOnView.patient?.biologicalSex || '').toLowerCase();
    }
    const categories = readCategories(this.$store);
    if (!categories.length) {
      return [...this.notes].sort(this.sortNoteByCategoryName);
    }
    const results = this.testResults;
    const availableCategories = intersectionWith(categories, results, (category, result) => {
      if (patientSex === 'male' && (category.categoryName || '').toLowerCase() === 'female health') {
        return false;
      }
      if (patientSex === 'female' && (category.categoryName || '').toLowerCase() === 'male health') {
        return false;
      }
      if (category.biomarkers) {
        return category.biomarkers.some((biomarker) => biomarker.name === result.biomarkerName);
      }
      return false;
    });
    availableCategories.push({
      categoryName: 'Summary',
    });
    const notes = this.notes.map((note) => {
      if (note.category) {
        return note;
      }
      return {
        ...note,
        category: {
          categoryName: 'Summary',
        },
      };
    });
    const emptyNotes = availableCategories.map(this.categoryToEmptyNote);
    return unionBy(notes, emptyNotes, 'category.categoryName').sort(this.sortNoteByCategoryName);
  }

  get user() {
    return readUserProfile(this.$store);
  }

  public quickViewDialog = false;
  public savedNoteIdx: number | null = null;

  public changedNotes = new Set<string>([]);

  public mounted() {
    this.fetchNotes();
  }

  public created() {
    dispatchGetCategories(this.$store);
  }

  public isTheNoteCreator(note: INote) {
    return note.user?.id === this.user?.id;
  }

  public async goBackToResults() {
    const saved = await this.saveNotesContent();
    if (saved) {
      await this.$router.push(`/main/physician/results/${this.$route.params.requisitionId}`);
    }
  }

  public onNoteInput(note: INote) {
    this.changedNotes.add(note.id);
  }

  public async goToConfirmation() {
    let editorOverallNote;
    const savedOverallNote = this.groupedNotes.find((n) => !n.category.id && n.content?.trim()?.length);
    if (Array.isArray(this.$refs.editor)) {
      editorOverallNote = this.$refs.editor.find((editorRef: any) => {
        const value = editorRef.value.trim();
        const [, categoryId] = editorRef.id.split('|');
        if (!categoryId && value.length) {
          return true;
        }
      });
    }
    if (!(editorOverallNote || savedOverallNote)) {
      await dispatchAddNotification(this.$store, {
        message: 'Summary is required in order to approve the test result',
        color: 'warning',
      });
      return;
    }
    const saved = await this.saveNotesContent();
    if (saved) {
      await this.$nextTick();
      await this.$router.push(`/main/physician/results/${this.$route.params.requisitionId}/review/confirmation`);
    }
  }

  private async saveNotesContent() {
    if (Array.isArray(this.$refs.editor)) {
      await Promise.all(this.$refs.editor.map((editorRef: any) => {
        if (this.requisitionOnView?.id && this.user) {
          const [noteId, categoryId] = editorRef.id.split('|');

          const value = editorRef.value.trim();
          const isCreation = !noteId && !!value.length;

          let dispatchUpsertNote;
          if (isCreation) {
            dispatchUpsertNote = dispatchCreateNote;
          } else {
            dispatchUpsertNote = dispatchUpdateNote;
          }

          const noteContentChanged = this.changedNotes.has(noteId);

          const notePayload = {} as INoteInput;
          if (isCreation) {
            notePayload.requisitionId = this.requisitionOnView.id;
            if (!!categoryId) { notePayload.categoryId = categoryId; }
          }

          if (isCreation || noteContentChanged) {
            notePayload.content = editorRef.value;
          }

          if (dispatchUpsertNote && Object.keys(notePayload).length) {
            const result = dispatchUpsertNote(this.$store, {
              id: noteId,
              ...notePayload,
            });
            this.changedNotes.delete(noteId);
            return result;
          }

          return false;
        }
        return false;
      }).filter((i) => i)).then(() => true).catch(() => false);
    }
    this.fetchNotes();
    return true;
  }

  private categoryToEmptyNote(category: ICategory | null): INote {
    return {
      id: '',
      user: {},
      category,
      physicianNoteLedgers: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  }

  private sortNoteByCategoryName(a: INote, b: INote) {
    // checking if category is null then put it at the end, otherwise sort by category name
    if (!a.category?.id && !b.category?.id) {
      return 0;
    } else if (!a.category?.id) {
      return 1;
    } else if (!b.category?.id) {
      return -1;
    } else {
      return a.category.categoryName?.localeCompare(b.category?.categoryName || '') || 0;
    }
  }

  @Watch('requisitionOnView')
  private fetchNotes() {
    if (this.requisitionOnView?.id) {
      dispatchGetNotes(this.$store, this.requisitionOnView.id);
    }
  }
}
